const c = [
	() => import("..\\..\\..\\frontend\\player.meteotv.tv\\src\\routes\\__layout.svelte"),
	() => import("..\\..\\..\\frontend\\player.meteotv.tv\\src\\routes\\__error.svelte"),
	() => import("..\\..\\..\\frontend\\player.meteotv.tv\\src\\routes\\index.svelte")
];

const d = decodeURIComponent;

export const routes = [
	// frontend/player.meteotv.tv/src/routes/index.svelte
	[/^\/$/, [c[0], c[2]], [c[1]]]
];

// we import the root layout/error components eagerly, so that
// connectivity errors after initialisation don't nuke the app
export const fallback = [c[0](), c[1]()];